import React from 'react';
import PropTypes from 'prop-types';

import './Hamburger.scss';

const Hamburger = ({ type, active }) => (
  <div className={`hamburger ${type ? `hamburger--${type}` : ''} ${active ? 'is-active' : ''}`}>
    <div className="hamburger-box">
      <div className="hamburger-inner" />
    </div>
  </div>
);

Hamburger.propTypes = {
  type: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

Hamburger.defaultTypes = {
  type: '',
  active: false,
};

export default Hamburger;
