import { combineReducers } from 'redux';
import { reducer as OverlayNav } from '../components/OverlayNav';

const ui = combineReducers({
  OverlayNav,
});

export default combineReducers({
  ui,
});
