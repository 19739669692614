import {
  ACTION_OVERLAY_NAV_OPEN,
} from './constants';

import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_OVERLAY_NAV_OPEN:
      return state.set('open', action.open);

    default:
      return state;
  }
};
