import wrapWithStoreProvider from './wrapWithStoreProvider';

/*
export const replaceRouterComponent = ({ history }) => {
  const store = createStore({ isBrowser: true });

  const ConnectedRouterWrapper = ({ children }) => (
    <Provider store={store}>
      <Router history={history}>{ children }</Router>
    </Provider>
  );

  ConnectedRouterWrapper.propTypes = {
    children: PropTypes.element.isRequired,
  };

  return ConnectedRouterWrapper;
};
*/

export const wrapRootElement = wrapWithStoreProvider;
