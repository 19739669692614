import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import omit from 'lodash/omit';

import Hamburger from '../Hamburger';

import { overlayNavOpen } from './actions';
import { getOverlayNavIsOpen } from './selectors';

const BtnContainer = styled(props => <div {...omit(props, ['isOpen', 'burgerBreakpoint'])} />)`
  width: 40px;
  height: 40px;
  margin-right: 1.0875rem;
  cursor: pointer;
  user-select: none;

  @media (min-width: ${props => props.burgerBreakpoint + 1}px) {
    width: 0;
    overflow: hidden;
  }
`;

export const BurgerMenuBtn = ({ isOpen, onClick, burgerBreakpoint }) => (
  <BtnContainer
    isOpen={isOpen}
    onClick={() => onClick(!isOpen)}
    burgerBreakpoint={burgerBreakpoint}
  >
    <Hamburger type="spin" active={isOpen} />
  </BtnContainer>
);

BurgerMenuBtn.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  burgerBreakpoint: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  isOpen: getOverlayNavIsOpen(state),
});

const mapDispatchToProps = dispatch => ({
  onClick: open => dispatch(overlayNavOpen(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenuBtn);
