import './PageLink.scss';

import React, { Fragment } from 'react';

import Link from 'gatsby-link';
import PageLinkShape from './PageLinkShape';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import styled from 'styled-components';

const PageLink = styled(props => <Link {...omit(props, 'burgerBreakpoint')} />)`
  display: block;
  padding-right: 1em;

  @media (max-width: ${props => props.burgerBreakpoint}px) {
    display: none;
  }
`;

const HorizontalPagesNav = ({ pages, burgerBreakpoint }) => (
  <Fragment>
    { pages.map(({ path, navTitle }) => (
      <PageLink
        to={path}
        key={path}
        burgerBreakpoint={burgerBreakpoint}
        className="pageLink"
        activeClassName="pageLink--active"
      >
        { navTitle }
      </PageLink>
    ))}
  </Fragment>
);

HorizontalPagesNav.propTypes = {
  pages: PropTypes.arrayOf(PageLinkShape).isRequired,
  burgerBreakpoint: PropTypes.number.isRequired,
};

export default HorizontalPagesNav;
