import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import reducers from '../reducers';

const middleware = [];

export default ({ isBrowser } = {}) => {
  let composeEnhancers = compose;

  if (isBrowser) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
  }

  return reduxCreateStore(reducers, composeEnhancers(applyMiddleware(...middleware)));
};
