import './PageLink.scss';

import React, { Fragment } from 'react';

import Link from 'gatsby-link';
import PageLinkShape from './PageLinkShape';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PageLink = styled(Link)`
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;

  padding-left: 1rem;
  padding-right: 1rem;
`;

const VerticalPagesNav = ({ pages, onPageLinkClick }) => (
  <Fragment>
    { pages.map(({ path, navTitle }) => (
      <PageLink
        onClick={onPageLinkClick}
        to={path}
        key={path}
        className="pageLink pageLink--overlay"
        activeClassName="pageLink--active"
      >
        { navTitle }
      </PageLink>
    ))}
  </Fragment>
);

VerticalPagesNav.propTypes = {
  pages: PropTypes.arrayOf(PageLinkShape).isRequired,
  onPageLinkClick: PropTypes.func.isRequired,
};

export default VerticalPagesNav;
