import Layout from './src/components/Layout'
import { Provider } from 'react-redux';
import React from 'react';
import createStore from './src/state/createStore';

/*
export const replaceRouterComponent = ({ history }) => {
  const store = createStore({ isBrowser: true });

  const ConnectedRouterWrapper = ({ children }) => (
    <Provider store={store}>
      <Router history={history}>{ children }</Router>
    </Provider>
  );

  ConnectedRouterWrapper.propTypes = {
    children: PropTypes.element.isRequired,
  };

  return ConnectedRouterWrapper;
};
*/

export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()
  return <Provider store={store}><Layout>{element}</Layout></Provider>
}
