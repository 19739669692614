import './Layout.scss';

import React, { Fragment } from 'react';
import { StaticQuery, graphql } from "gatsby"

import Helmet from 'react-helmet';
import PageNavBar from '../PageNavBar';
import PropTypes from 'prop-types';
import readNodes from '../../utils/readNodes';
import siteConfig from '../../../site-config';

const Layout = ({
  children,
}) => (
  <Fragment>
    <Helmet
      title={`${siteConfig.page.htmlTitle} • ${siteConfig.page.description}`}
      meta={[
        { name: 'description', content: siteConfig.page.description },
        { name: 'keywords', content: siteConfig.page.keywords.join(', ') },
        { name: 'author', content: siteConfig.page.author },
      ]}
    />
    <Fragment>{children}</Fragment>
    <StaticQuery
      query={graphql`
        query layout {
          pageNodes: allNavigationYaml {
            edges {
              node {
                path
                navTitle
              }
            }
          }
        }
      `}
      render={data => <PageNavBar pages={readNodes(data.pageNodes)} />}
    />
  </Fragment>
);

Layout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Layout;
