import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import omit from 'lodash/omit';

import { overlayNavOpen } from './actions';
import { getOverlayNavIsOpen } from './selectors';

const Overlay = styled(props => <div {...omit(props, ['isOpen', 'burgerBreakpoint'])} />)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => (props.isOpen ? '100vh' : '0')};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  overflow: hidden;
  transition: opacity 0.3s ease-out;

  background-color: #fff;

  @media (min-width: ${props => (props.burgerBreakpoint || 0) + 1}px) {
    height: 0;
    opacity: 0;
  }
`;

const OverlayNav = ({
  children,
  isOpen,
  close,
  burgerBreakpoint,
}) => (
  <Overlay isOpen={isOpen} burgerBreakpoint={burgerBreakpoint}>
    { children(close) }
  </Overlay>
);

OverlayNav.propTypes = {
  burgerBreakpoint: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isOpen: getOverlayNavIsOpen(state),
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(overlayNavOpen(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverlayNav);
