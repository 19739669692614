import './PageNavBar.scss';

import {
  BurgerMenuBtn,
  OverlayNav,
} from '../OverlayNav';

import HorizontalPagesNav from './HorizontalPagesNav';
import PageLinkShape from './PageLinkShape';
import PropTypes from 'prop-types';
import React from 'react';
import VerticalPagesNav from './VerticalPagesNav';
import { navigate } from 'gatsby-link';
import siteConfig from '../../../site-config';
import styled from 'styled-components';

const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.12), inset 0 -1px 0 0 #dadce0
`;
//   border-bottom: 1px solid #f3f5f7;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
`;

const Home = styled.header`
  padding: 12px 3rem 12px 0;
  cursor: pointer;

  @media (max-width: 480px) {
    padding-right: 0;
  }
`;

const Title = styled.div`
  color: #333;
  font-size: 18px;
  font-weight: 700;
  line-height 1.1;
  margin-bottom: 0.2em;
`;

const SubTitle = styled.div`
  color: #666;
  font-size: 14px;
  line-height 1.1;
`;

const HorizontalSpacer = styled.div`
  flex: 1 0 0;
  height: 0;
`;

const VerticalNav = styled.div`
  display: flex;
  height: 100%;
  padding-top: 25px;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @media (orientation: landscape) and (max-height: 500px) {
    padding-top: 10px;
    flex-direction: row;
    justify-content: center;
  }
`;

const OverlayMenuLayout = styled.div`
  text-align: center;
  margin-top: 70px;
`;

const MenuHeadline = styled.h1`
  color: #666;
  background-image: linear-gradient(to right, #666 0%, #333 100%);
  font-size: 1.75rem;
`;

const BURGER_BREAKPOINT = 740; // 840 / 940;

const goHome = () => navigate('/');

const homePage = {
  path: '/',
  navTitle: 'Startseite',
};

const PageNavBar = ({ pages }) => (
  <Container>
    <OverlayNav burgerBreakpoint={BURGER_BREAKPOINT}>
      { closeOverlay => (
        <OverlayMenuLayout>
          <MenuHeadline>Inhalt</MenuHeadline>
          <VerticalNav>
            <VerticalPagesNav pages={[homePage].concat(pages)} onPageLinkClick={closeOverlay} />
          </VerticalNav>
        </OverlayMenuLayout>
      )}
    </OverlayNav>
    <Content className="pageNavBar__content">
      <Home onClick={goHome}>
        <Title>{ siteConfig.page.htmlTitle }</Title>
        <SubTitle>{ siteConfig.page.description }</SubTitle>
      </Home>
      <HorizontalPagesNav pages={pages} burgerBreakpoint={BURGER_BREAKPOINT} />
      <HorizontalSpacer />
      <BurgerMenuBtn burgerBreakpoint={BURGER_BREAKPOINT} />
    </Content>
  </Container>
);

PageNavBar.propTypes = {
  pages: PropTypes.arrayOf(PageLinkShape).isRequired,
};

export default PageNavBar;
